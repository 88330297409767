import React from 'react'
import styled from 'styled-components'
import Fade from 'react-reveal/Fade'
import Layout from './../components/layout'
import PageHeading from './../components/page-heading'
import Container from './../components/container'

export default props => {
  return (
    <Layout pageName="enquiries" documentTitlePrefix="Enquiries">
      <PageHeading heading="Enquiries" />
      <Container>
        <Fade>
          <StyledThanksMessage>
            <p>Thanks for contacting us.</p>
            <p>We&rsquo;ll be in touch soon.</p>
          </StyledThanksMessage>
        </Fade>
      </Container>
    </Layout>
  )
}

const StyledThanksMessage = styled.div`
  font-weight: 100;
  margin-top: 16rem;
  text-align: center;

  p {
    margin-bottom: 0;
  }
`
